import { VjsPlayerComponent } from './vjs-player/vjs-player.component';
import { PromptComponent } from './prompt/prompt.component';
import { DesignSystemModule } from 'src/theme/design-system/design-system.module';
import { PopoverComponent } from './popover/popover.component';
import { InvoiceFormComponent } from './invoice/invoice-form/invoice-form.component';
import { InvoiceListComponent } from './invoice/invoice-list/invoice-list.component';
import { InvoiceDetailComponent } from './invoice/invoice-detail/invoice-detail.component';
import { TableHistoryComponent } from './history-points/table-history/table-history.component';
import { PushNotificationsManagerComponent } from './push-notifications-manager/push-notifications-manager.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar/avatar.component';
import { ProfileComponent } from './profile/profile.component';
import { ContactModalComponent } from './contact-modal/contact-modal.component';
import { ProfileModalComponent } from './profile-modal/profile-modal.component';
import { IonicModule } from '@ionic/angular';

import { PipesModule } from '../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HeaderComponent } from './header/header.component';
import { BottomSheetComponent } from './bottom-sheet/bottom-sheet.component';
import { CheckoutStatusComponent } from './checkout-status/checkout-status.component';
import { CardContentComponent } from './card-content/card-content.component';
import { CardContentSkeletonComponent } from './card-content/skeleton/card-content-skeleton/card-content-skeleton.component';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-notfound/page-not-found/page-not-found.component';
import { SlideExhibitionsComponent } from './slide-exhibitions/slide-exhibitions.component';
import { IconProfileComponent } from './svg/icon-profile/icon-profile.component';
import { IconPointsComponent } from './svg/icon-points/icon-points.component';
import { IconAddressComponent } from './svg/icon-address/icon-address.component';
import { IconOrdersComponent } from './svg/icon-orders/icon-orders.component';
import { IconConfigurationComponent } from './svg/icon-configuration/icon-configuration.component';
import { MenuComponent } from './menu/menu.component';
import { CatalogueDetailComponent } from './catalogue-detail/catalogue-detail.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { HistoryPointsComponent } from './history-points/history-points.component';
import { CameraComponent } from './camera/camera.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderLoginComponent } from './header-login/header-login.component';
import { InfoGeneralComponent } from './history-points/info-general/info-general.component';
import { IconRecognitionsComponent } from './svg/icon-recognitions/icon-recognitions.component';
import { CheckoutFormsModule } from './checkout-forms/checkout-forms.module';
import { ModalAlertsComponent } from './modal-alerts/modal-alerts.component';
import { CartProductComponent } from './cart-product/cart-product.component';
import { BannerComponent } from './banner/banner.component';
import { ModalInstructionsComponent } from './modal-instructions/modal-instructions.component';
import { GooglemapsComponent } from '../pages/private/map/googlemaps/google-maps.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { LoaderComponent } from './loader/loader.component';
import { ImageComponent } from './image/image.component';
import { ItemContentComponent } from './item-content/item-content.component';
import { PinchZoomModule } from 'ngx-pinch-zoom';

@NgModule({
  declarations: [
    AvatarComponent,
    ProfileComponent,
    ContactModalComponent,
    ProfileModalComponent,
    HeaderComponent,
    BottomSheetComponent,
    CheckoutStatusComponent,
    CardContentComponent,
    CardContentSkeletonComponent,
    PageNotFoundComponent,
    SlideExhibitionsComponent,
    IconProfileComponent,
    IconPointsComponent,
    IconAddressComponent,
    IconOrdersComponent,
    IconConfigurationComponent,
    IconRecognitionsComponent,
    MenuComponent,
    CatalogueDetailComponent,
    HistoryPointsComponent,
    CameraComponent,
    HeaderLoginComponent,
    FooterComponent,
    PopoverComponent,
    InfoGeneralComponent,
    TableHistoryComponent,
    PromptComponent,
    ModalAlertsComponent,
    PushNotificationsManagerComponent,
    CartProductComponent,
    BannerComponent,
    VjsPlayerComponent,
    ModalInstructionsComponent,
    GooglemapsComponent,
    LoaderComponent,
    ImageComponent,
    ItemContentComponent,
    VjsPlayerComponent
  ],
  exports: [
    AvatarComponent,
    ProfileComponent,
    ContactModalComponent,
    ProfileModalComponent,
    HeaderComponent,
    BottomSheetComponent,
    CheckoutStatusComponent,
    CardContentComponent,
    CardContentSkeletonComponent,
    PageNotFoundComponent,
    SlideExhibitionsComponent,
    IconProfileComponent,
    IconPointsComponent,
    IconAddressComponent,
    IconOrdersComponent,
    IconConfigurationComponent,
    IconRecognitionsComponent,
    MenuComponent,
    CatalogueDetailComponent,
    HistoryPointsComponent,
    CameraComponent,
    HeaderLoginComponent,
    FooterComponent,
    PopoverComponent,
    InfoGeneralComponent,
    TableHistoryComponent,
    ModalAlertsComponent,
    PushNotificationsManagerComponent,
    CartProductComponent,
    BannerComponent,
    VjsPlayerComponent,
    ModalInstructionsComponent,
    GooglemapsComponent,
    LoaderComponent,
    VjsPlayerComponent,
    ImageComponent,
    ItemContentComponent,
    PinchZoomModule
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    DesignSystemModule,
    CheckoutFormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gmApiKey,
      libraries: ['geometry', 'places'],
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
