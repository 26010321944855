import { environment } from 'src/environments/environment';
import { ICurrencyCodeProgram } from './../../interfaces/ILangs';
import { LanguagesService } from './../languagesService/languages.service';
import { EventEmitter, Injectable } from "@angular/core";
import { AngularFirestore, DocumentData, QuerySnapshot } from "@angular/fire/firestore";
import { from, Observable, Subscription, timer } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";
import * as firebase from "firebase/app";
import { ModalController } from "@ionic/angular";
import { RemoteConfigService } from "../remote-config/remote-config.service";
import { ProfileModalComponent } from "src/app/components/profile-modal/profile-modal.component";
import { StorageService } from "../storage/storage.service";
import { DocumentReference, FirebaseFirestore } from "@firebase/firestore-types";
import { KEY_AVATAR_PROFILE } from 'src/app/components/avatar/avatar.component';
import { HttpClient } from '@angular/common/http';
import { PlatformService } from '../platform/platform.service';



@Injectable({
  providedIn: "root",
})
export class UserService {
  subscription
  apiKey
  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService,
    private modalController: ModalController,
    public remoteConfig: RemoteConfigService,
    public storageService: StorageService,
    private languageService : LanguagesService,
    private http: HttpClient,
    private platformService : PlatformService,
  ) { 
    this.getClaims();
    this.setApiKeyByPlatform();
  }

  phone;
  claims: any;
  public termCondition$ = new EventEmitter<any>();
  async setApiKeyByPlatform() {
    const plat = this.platformService.getPlatform();
    if (
      plat.includes("mobileweb") ||
      plat.includes("desktop") ||
      plat.includes("tablet")
    ) {
      return (this.apiKey = environment.gluky.apiKeyWeb);
    }
    if (plat.includes("pwa")) {
      return (this.apiKey = environment.gluky.apikeyPwa);
    }
    if (plat.includes("android")) {
      return (this.apiKey = environment.gluky.apiKeyAndroid);
    }
    if (plat.includes("ios")) {
      //TODO add paike to ios
      return (this.apiKey = environment.gluky.apiKeyAndroid);
    }
  }

  getDataUserExtraInfo(): Observable<any> {
    return from(this.storageService.get("gnx-claims")).pipe(
      switchMap(claims => {
        let programs, program;
        console.log('claims', claims);

        if (claims) {
          const programId = claims.programs;
          programs = programId[0].split("/");
          program = programs.slice(-1).pop();
        }

        // Retornar el Observable del `http.get`
        return this.http.get(
          `${environment.gluky.usersApiBasePath}/frontend/app_users/${claims.uid}/profile/config`
        );
      })
    );
  }
  async patchDataUserExtraInfo(dataForm){
    const claims = await this.storageService.get("gnx-claims");
    return this.http.patch(
      `${environment.gluky.usersApiBasePath}/frontend/app_users/${claims.uid}/profile`,{data:{...dataForm}}//
    );

  }


  getDataUserExtraInfoProfile(): Observable<any> {
    return from(this.storageService.get("gnx-claims")).pipe(
      switchMap(claims => {
        let programs, program;
        console.log('claims', claims);

        if (claims) {
          const programId = claims.programs;
          programs = programId[0].split("/");
          program = programs.slice(-1).pop();
        }

        // Retornar el Observable del `http.get`
        return this.http.get(
          `${environment.gluky.usersApiBasePath}/frontend/app_users/${claims.uid}/profile`
        );
      })
    );
  }

  async getClaims(){
    try {
      return this.claims = await this.storageService.get('gnx-claims');
    } catch (error) {
      throw new Error(error);
    }
  }
  async saveUserExtraInfo(data, uid = "") {
    
    const docId = uid != "" ? uid : this.authService.userGnxId;
    console.log('saveUserExtraInfo uid ',docId);
    const claims = await this.storageService.get("gnx-claims");
    console.log('saveUserExtraInfo claims ',claims);

    
    const docExists = await this.verifyIfDocExist(docId);
    const ref = await this.firestore.collection("user-info").doc(docId);

    console.log('saveUserExtraInfo(', 'docExists 1' , docExists);
    if (docExists) {
      const update =  ref.update(data);
      update.then(async () => {
        console.log('saveUserExtraInfo(', 'update' , update);
      }).catch(async (error) => {
        console.log('saveUserExtraInfo(', 'update error' , error)});
      return update;
    }
    console.log('saveUserExtraInfo( ref', ref );
   //  return ref.set(data);
   const time =  timer(100, 2000);
   this.subscription = time.subscribe(t => {
    const setter =  ref.set(data);
    console.log('saveUserExtraInfo( ref.set(data) s' , setter);
    setter.then(async () => {
      console.log('saveUserExtraInfo(', 'setter' , setter);
      const docExists = await this.verifyIfDocExist(docId);
      if (docExists) {
        this.subscription.unsubscribe();
        return setter
      }
      
    }).catch(async (error) => {
      console.log('saveUserExtraInfo(', 'setter error' , error);
    });
    
  //  return setter; 
  })
    
  
  }

  getUserInfo() {
    try {
      const uid = (this.authService.userGnxId) ? this.authService.userGnxId : this.claims.uid;
      console.log("GET USER INFO",uid);
      return this.firestore
        .collection("user-info")
        .doc(uid)
        .valueChanges();
    } catch (error) {
      console.log("error get user info",error);
      throw new Error(error);
    }
  }

  async getUserInfoWithoutSub(id?) {
    const docId = id ? id : this.authService.userGnxId;
      const user_ref = await this.firestore
        .collection("user-info")
        .doc(docId)
        .ref.get()
      const userInfo: any = (await user_ref).data();
      return userInfo
    }
  


  getUserInfoWithId(id?) {
    const docId = id ? id : this.authService.userGnxId;
    return this.firestore
      .collection("user-info")
      .doc(docId)
      .snapshotChanges()
      .pipe(
        map((action) => {
          const data: any = action.payload.data();
          if(data) data.id = action.payload.id;
          return data;
        })
         
      );
  } 
  // async validateDataSAML():Promise<boolean>{
  //   const objectSAML = this.storageService.get("objectSAML");
    
  //   return true;
  // }

  async checkIfProfileComplete() { 
    try {
      let observableUser: Subscription;
    const chatInfo = await this.remoteConfig.getLoginChat().toPromise();
    await new Promise((resolve) => {
      const objectSAML:any = this.storageService.get("objectSAML");

      observableUser = this.getUserInfo().subscribe( async (user:any) => {
        console.log("checkifprofile",user, !user, this.authService);
        if(!user){ 
          //no hay usuario
          const data = {
            programId: this.authService.programId[0],
            notificationTerms: false,
            phone: this.authService.phone,
            gnxCredential1: (this.authService.gnxCredential1) ? this.authService.gnxCredential1 : this.claims.uid,
            firebaseCredential1: (this.phone) ? this.phone : ((this.authService.phone) ? this.authService.phone : ''),
            name:objectSAML.name,
            lastnames:objectSAML.lastnames,
            docType:objectSAML.docType,
            docNumber:objectSAML.docNumber,
            genre:objectSAML.genre,
          }
          console.log("data !user", data);
         await this.saveUserExtraInfo(data); 
        }
        console.log("CHAT INFO", chatInfo);
        const editables = chatInfo.filter(
          (question) => question.in_profile_page_editable === true
        );
        console.log("CAMPOS EDITABLES", editables);
        if(user==undefined || user.name == undefined || user.lastnames ==undefined){
          resolve({ showModal: true, values: { editables, user } });
          // const keysUser= Object.getOwnPropertyNames(user);
        }else{
         // this.termCondition$.emit("true");
          const keysUser = Object.getOwnPropertyNames(user);
          editables.map((editable) => { 
            console.log("editable", editable);
          if (!keysUser.includes(editable.name)) {
            resolve({ showModal: false, values: { editables, user } });
            }
          });
        }

      });
    }).then(async (state: any) => { 
      if (state.showModal) {
      //  await this.presentModalToCompleteInfo(state.values);
      }
      observableUser.unsubscribe();
    }).catch((err)=>{
      console.error('catch((err) Promise ', err)
      Promise.reject(err)
    });
    } catch (error) {
      console.error('catch((err) try-catch ', error)
      Promise.reject(error)
    }
    
  }

  async presentModalToCompleteInfo(values) {
    const modal = await this.modalController.create({
      component: ProfileModalComponent,
      backdropDismiss: false,
      componentProps: {
        values,
      },
      cssClass: 'my-custom-class gnx-modal',
    });

    modal.onDidDismiss().then((valuesForm) => {
      this.saveUserExtraInfo(valuesForm.data, '');
    //  this.termCondition$.emit("true");
    });
    return await modal.present();
  }
  
  getAllUserType() {
    const body = {
      app_id: environment.gluky.app_id,
    }
    return this.authService.getRegisterConfigData(body).toPromise();
  }


  exitsActiveAddress() {
    return new Observable((observer) => {
      const data = this.firestore
        .collection("user-info")
        .doc(this.authService.userGnxId)
        .valueChanges();
      
      data.subscribe((doc: any) => { 
        if (doc.activeAddress && Object.keys(doc.activeAddress).length > 0 ) {
          observer.next(true);
        } else {
          observer.error(true);
        }
      });
    });
  }

  async savePictureOnfirestore(url) {
    const docExists = await this.verifyIfDocExist(this.authService.userGnxId);
    const ref = this.firestore
      .collection("user-info")
      .doc(this.authService.userGnxId);
    if (docExists) {
      return ref.update({ urlAvatar: url });
    }
    return ref.set({ urlAvatar: url });
  }

  async updateField(field, data) {
    await this.firestore
      .collection("user-info")
      .doc(this.authService.userGnxId)
      .update({ [field]: data });
  }

  async verifyIfDocExist(uid) { 
    const cityRef = this.firestore.collection('user-info').doc(uid);
    const doc = await cityRef.get().toPromise();
    if (doc.exists) {
      console.log('Document data:', doc.data());
      return true;
    } else {
      console.log('No such document!');      
      return false;
    }   
  }

  async removeFields(field) {
    return new Promise((resolve) => {
      this.firestore
        .collection("user-info")
        .doc(this.authService.userGnxId)
        .update({
          [field]: firebase.default.firestore.FieldValue.delete(),
        });
    });
  }
  async acceptTermsConditions() {
    const fecha= new Date();
    const docExists = await this.verifyIfDocExist(this.authService.userGnxId);
    const ref = this.firestore
      .collection("user-info")
      .doc(this.authService.userGnxId);
    if (docExists) {
      return ref.update({ termsConditions: fecha });
    }
    return ref.set({ termsConditions: fecha });
  }


  async getCurrencyCodeProgram(){
    let programs
    let program
    let CurrencyCodeProgram : Array<ICurrencyCodeProgram>

    if (this.authService.programId && this.authService.programId[0]) {
       programs = this.authService.programId[0].split("/");
       program = programs.slice(-1).pop();
    }else {
      const claims = await this.storageService.get("gnx-claims");
      if (claims) {
        const programId = claims.programs;
         programs = programId[0].split("/");
         program = programs.slice(-1).pop();
      }
    }

     CurrencyCodeProgram = this.languageService.textsIU.currency_data.currency_Code_Program.filter((data)=>{
     
      return data.program == program
    })
    
    return CurrencyCodeProgram[0]?.Code ? CurrencyCodeProgram[0].Code : 'COP'
  }

  async GetImageProfileUser() {
    const avatar = await this.storageService.get(KEY_AVATAR_PROFILE);
    if (avatar) {
      return avatar
    } else {
      const user_ref = await this.firestore
        .collection("user-info")
        .doc(this.authService.userGnxId)
        .ref.get()
      const userInfo: any = (await user_ref).data();
      if (userInfo.urlAvatar) {
        this.storageService.set(KEY_AVATAR_PROFILE, userInfo.urlAvatar )
      }
      return userInfo.urlAvatar
    }
  }
}
